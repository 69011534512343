<template>
  <v-btn class="ml-10 white--text" color="#33cc99" to="/new-journal-entry">
    <v-icon left small>mdi-plus</v-icon>New Journal Entry</v-btn
  >
</template>
<script>
export default {
  name: "AddJournalEntryButton",
  data() {
    return {};
  },
};
</script>
